function resizeDesc() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const desktopEl = document.getElementById('company-description-desktop');
    const mobileEl = document.getElementById('company-description-mobile');
    if (desktopEl && mobileEl) {
        if (vw <= 768) {
            if (desktopEl.innerHTML.length > mobileEl.innerHTML.length) {
                mobileEl.innerHTML = desktopEl.innerHTML;
                desktopEl.innerHTML = '';
            }
        }
        else {
            if (mobileEl.innerHTML.length > desktopEl.innerHTML.length) {
                desktopEl.innerHTML = mobileEl.innerHTML;
                mobileEl.innerHTML = '';
            }
        }
    }
}

if (document.getElementById('company-description-desktop')) {
    window.addEventListener('resize', resizeDesc);
    resizeDesc();
}
