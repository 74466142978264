if (document.querySelector('[name="anonymous"]')) {
    function setAnonymous(radio) {
        const firstname = document.getElementById('firstname');
        const lastname = document.getElementById('lastname');
        firstname.value = radio.value;
        if (parseInt(radio.dataset.anonymous)) {
            firstname.setAttribute('readonly', true);
            lastname.parentNode.style.display = 'none';
            lastname.setAttribute('disabled', true);
        } else {
            firstname.removeAttribute('readonly');
            lastname.parentNode.style.display = 'block';
            lastname.removeAttribute('disabled');
        }
    }

    document.querySelectorAll('[name="anonymous"]').forEach(radio => {
        radio.addEventListener('change', event => {
            setAnonymous(event.target);
        });
    });
}
