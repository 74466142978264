const alert = document.querySelector('#verified-alert');
if (alert) {
    alert.style.opacity = 1;
    window.setTimeout(function () {
        (function fade() {
            if ((alert.style.opacity -= .05) < 0) {
                alert.style.display = "none";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }, 5000);
}
