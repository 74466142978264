document.addEventListener("DOMContentLoaded", function (e) {
    document.querySelectorAll('.btn-visit-website').forEach(btn => {
        btn.addEventListener('click', function () {
            let route = this.dataset.route;
            route = atob(route);
            window.open(route, '_blank');
            if (window.companyPopup) {
                window.companyPopup.hide();
            }
        });
    })
});
