if (document.getElementById('reviewForm')) {
    function checkBuyAgain() {
        const alertContainer = document.getElementById('buy-again-alert');

        const ratingOrder = document.querySelector('input[name="rating_order"]').value;
        const ratingPrice = document.querySelector('input[name="rating_price"]').value;
        const ratingDelivery = document.querySelector('input[name="rating_delivery"]').value;
        const ratingService = document.querySelector('input[name="rating_service"]').value;
        const sum = parseInt(ratingOrder) + parseInt(ratingPrice) + parseInt(ratingDelivery) + parseInt(ratingService);
        const averageRating = sum / 4 * 2;

        const buyAgain = document.querySelector('input[name="buy_again"]:checked').value;

        if (buyAgain == 'no' && averageRating > 7) {
            alertContainer.innerHTML = alertContainer.dataset.text1;
            alertContainer.classList.remove('d-none');
        }
        else if (buyAgain == 'yes' && averageRating < 5) {
            alertContainer.innerHTML = alertContainer.dataset.text2;
            alertContainer.classList.remove('d-none');
        }
        else {
            alertContainer.classList.add('d-none');
        }
    }

    checkBuyAgain();
    document.getElementById('reviewForm').querySelectorAll('input').forEach(input => input.addEventListener('change', checkBuyAgain));
    document.getElementById('reviewForm').querySelectorAll('.stars-select').forEach(starsSelect => starsSelect.addEventListener('click', checkBuyAgain));
}
