require('./bootstrap');

require('./app/navbar');
require('./app/log');
require('./app/search');

require('./app/visit-website-button');
require('./app/report');
require('./app/resize-desc');
require('./app/stars-select');
require('./app/read-more-button');
require('./app/change-buy-again');
require('./app/change-anonymous');
require('./app/verified-alert');
