document.addEventListener("DOMContentLoaded", function (e) {
    document.querySelectorAll('.review-description').forEach(function (review) {
        if (review.scrollHeight > review.clientHeight) {
            if (review.closest('.card').querySelector('.btn-read-more')) {
                review.closest('.card').querySelector('.btn-read-more').classList.remove('d-none');
                review.closest('.card').querySelector('.btn-read-more').addEventListener('click', function () {
                    review.classList.add('review-description-full');
                    this.classList.add('d-none');
                });
            }
        }
    });
});
