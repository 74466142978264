function setNavbar() {
    if (window.scrollY) {
        document.body.classList.add('is-scrolled');
    }
    else {
        document.body.classList.remove('is-scrolled');
    }

    if (window.scrollY > document.querySelector('.header').clientHeight - document.getElementById('navbar-main').clientHeight) {
        document.body.classList.add('is-scrolled-header');
    }
    else {
        document.body.classList.remove('is-scrolled-header');
    }

    if (document.getElementById('reviewForm') && window.innerWidth > 767) {
        document.querySelector('#navbar-main').style.position = 'absolute';
    } else {
        document.querySelector('#navbar-main').style.position = 'fixed';
    }
}
document.addEventListener('scroll', setNavbar, { passive: true });
setNavbar();

if (document.querySelector('#menu-scrolled')) {
    document.querySelector('#menu-scrolled').addEventListener('click', event => {
        document.querySelector('.header-company-scrolled').classList.add('lowZindex');
    });

    document.querySelector('.menu-close').addEventListener('click', event => {
        document.querySelector('.header-company-scrolled').classList.remove('lowZindex');
    });
}
