function searchInputEvent(form, input, timer, time) {
    const searchRoute = document.querySelector('meta[name="search-route"]')?.content;

    if (timer) {
        clearTimeout(timer);
    }
    timer = setTimeout(() => {
        const search = input.value;
        let xhttp = new XMLHttpRequest();
        xhttp.open("POST", searchRoute, true);
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                const result = JSON.parse(xhttp.responseText);
                showSearchCompanies(form, result);
            }
        };

        xhttp.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]')?.content ?? '');
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send(JSON.stringify({ json: true, q: search }));
    }, time);
}

function showSearchCompanies(form, result) {
    companies = Object.entries(result.companies);

    form.querySelector('.search-results')?.remove();
    const searchResult = document.createElement('div');
    searchResult.classList.add('search-results');

    if (companies.length) {
        companies.forEach(companyRow => {
            const [companyKey, company] = companyRow;
            let companyDiv = document.createElement('div');

            let reviewsCountText = window.translations?.general?.reviews_count?.replace('12345', company.reviews) ?? '';
            if (parseInt(company.reviews) == 1) {
                reviewsCountText = window.translations?.general?.reviews_count_single ?? '';
            }

            companyDiv.innerHTML = (`
                <a href="${company.url}" class="card company-thumbs mt-1">
                    <div class="card-body align-items-center d-flex">
                        <div class="row w-100 align-items-center">
                            <div class="col-4 col-md-1">
                                <img src="${company.logo}" alt="${company.name} logo" class="img-company-thumb float-start" width="50px" height="50px" style="padding: 2px;">
                            </div>
                            <div class="col-7 col-md-4">
                                <span class="mb-0 h3"><b>${company.name}</b></span>
                            </div>
                            <div class="col-4 text-end text-muted d-none d-md-block">
                                <i class="fal fa-comment-alt"></i> ${reviewsCountText}
                            </div>
                            <div class="col-3 text-end text-muted d-none d-md-block">
                                <div class="stars">
                                    <span class="stars-block">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            `);
            let companyRating = Math.round(company.rating / 2);
            if (company.rating < 1) {
                companyRating = 5;
            }
            companyDiv.querySelectorAll('.fa-star').forEach(function (star, index) {
                if (index < companyRating) {
                    star.classList.add('star-active');
                }
            });
            searchResult.append(companyDiv);
        });

        if (result.total > companies.length) {
            const buttonDiv = document.createElement('div');
            buttonDiv.classList.add('text-end');
            buttonDiv.classList.add('mt-1');

            const resultCountText = window.translations?.general?.results_count?.replace('12345', result.total) ?? '';
            buttonDiv.innerHTML = (`
                <button type="submit" class="btn btn-primary">${resultCountText}</button>
            `);
            searchResult.append(buttonDiv);
        }
    }
    else {
        const noCompanyDiv = document.createElement('div');
        noCompanyDiv.classList.add('card');
        noCompanyDiv.innerHTML = (`
            <div class="card-body">
                ${result.message}
            </div>
        `);
        searchResult.append(noCompanyDiv);
    }
    form.append(searchResult);
}

document.querySelectorAll('.search-form').forEach(function (form) {
    const input = form.querySelector('input');
    let timer;
    input.addEventListener('input', function () {
        searchInputEvent(form, input, timer, 250);
    });
    input.addEventListener('focus', function () {
        if (!input.value.length) {
            return;
        }
        searchInputEvent(form, input, timer, 1);
    });

    document.addEventListener('click', function (e) {
        if (form !== e.target && !form.contains(e.target)) {
            form.querySelector('.search-results')?.remove();
        }
    });
});
