document.querySelectorAll('.btn-report-review').forEach(button => {
    button.addEventListener('click', event => {
        const name = button.closest('.card-review').querySelector('.review-name').textContent;
        document.querySelectorAll('.report-review-name').forEach(nameDiv => {
            nameDiv.innerHTML = name;
        });

        const action = button.dataset.action;
        document.querySelectorAll('.report-review-form').forEach(form => {
            form.action = action;
        });

        const reportReviewPopup = new bootstrap.Modal(document.getElementById('report-review-popup'));
        reportReviewPopup.show();
    });
});
