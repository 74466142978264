document.querySelectorAll('.stars-select').forEach(starsSelect => {
    starsSelect.addEventListener('mouseover', event => {
        const stars = Array.from(starsSelect.querySelectorAll('.fa-star'));
        if (event.target.classList.contains('fa-star')) {
            const count = stars.indexOf(event.target);
            stars.forEach(star => {
                if (stars.indexOf(star) <= count) {
                    star.classList.add('text-warning');
                }
                else {
                    star.classList.remove('text-warning');
                }
            });

            const amount = count + 1;
            starsSelect.querySelector('.stars-select-amount').innerHTML = amount * 2;
            if (starsSelect.dataset.submit == '1') {
                starsSelect.querySelector('input').value = amount;
            }
        }
    });

    if (starsSelect.dataset.submit == '1') {
        starsSelect.addEventListener('click', event => {
            event.target.closest('form').submit();
        });
    }
    else {
        starsSelect.addEventListener('click', event => {
            const amount = starsSelect.querySelectorAll('.fa-star.text-warning').length;
            starsSelect.querySelector('input').value = amount;
            starsSelect.querySelector('.stars-select-amount').innerHTML = amount * 2;
        });

        starsSelect.addEventListener('mouseout', event => {
            starsSelect.querySelector('.stars-select-amount').innerHTML = starsSelect.querySelector('input').value * 2;
            const count = starsSelect.querySelector('input').value - 1;
            const stars = Array.from(starsSelect.querySelectorAll('.fa-star'));
            stars.forEach(star => {
                if (stars.indexOf(star) <= count) {
                    star.classList.add('text-warning');
                }
                else {
                    star.classList.remove('text-warning');
                }
            });
        });
    }
});
