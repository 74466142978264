const queryParamResults = new RegExp('[\\?&]gclid=([^&#]*)').exec(window.location.href);
const gaid = queryParamResults ? (queryParamResults[1] || null) : null;

const suffix = document.querySelector('meta[name="suffix"]')?.content;
let prefix = '';
if (suffix && suffix.length) {
    prefix = '/' + suffix;
}

const xhttp = new XMLHttpRequest();
xhttp.open("GET", prefix + "/log?referer=" + btoa(document.referrer ?? '') + '&gaid=' + btoa(gaid ?? ''), true);
xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
        const logData = JSON.parse(xhttp.responseText);
        if (logData.popup && document.getElementById('modal-company-popup')) {
            setTimeout(() => {
                window.companyPopup = new bootstrap.Modal(document.getElementById('modal-company-popup'), {
                    keyboard: false,
                    backdrop: 'static'
                });
                companyPopup.show();
            }, 2000);
        }
        if (logData.admin) {
            showAdminButtons(logData);
        }

        if (logData.statistics) {
            showStatisticsButton(logData);
        }
    }
};
xhttp.send();

function showStatisticsButton(logData) {
    const companyHeader = document.querySelector('.header-company');
    if (companyHeader) {
        const col = companyHeader.querySelector('.row').lastElementChild;
        if (col) {
            const button = document.createElement('a');
            button.href = logData.route;
            button.innerHTML = '<i class="fas fa-chart-line"></i> '+logData.btnText+'';
            button.classList.add('btn', 'btn-lg', 'btn-info', 'me-2', 'text-white');
            button.setAttribute('target', '_blank');
            col.prepend(button);
        }
    }
}

function showAdminButtons(logData) {
    document.querySelectorAll('.card-review').forEach((card) => {
        const cardId = card.id.split('-').pop();
        const col = card.querySelector('.btn-report-review')?.parentElement;
        if (col) {
            const button = document.createElement('a');
            button.href = '/admin/reviews/' + cardId + '/edit';
            button.innerHTML = logData.btnText;
            button.classList.add('btn', 'btn-link', 'p-0', 'me-2');
            button.setAttribute('target', '_blank');
            col.prepend(button);
        }
    });

    const companyHeader = document.querySelector('.header-company');
    if (companyHeader) {
        const col = companyHeader.querySelector('.row').lastElementChild;
        if (col) {
            const button = document.createElement('a');
            button.href = logData.route;
            button.innerHTML = '<i class="fas fa-user-secret"></i> Bewerken';
            button.classList.add('btn', 'btn-lg', 'btn-danger', 'me-2');
            button.setAttribute('target', '_blank');
            col.prepend(button);
        }
    }
}
